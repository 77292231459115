import * as api from 'utils/axios';
import * as endpoints from 'services/auth/endpoints';
// config
import { AUTH_API, X_APP_ID } from 'config';

export const loginService = (params?: any) =>
  api.sendPost(AUTH_API + endpoints.LOGIN, params, { headers: { 'X-App-Id': X_APP_ID } });

export const logoutService = () =>
  api.sendPost(AUTH_API + endpoints.LOGOUT, {});
export const smartLogoutService = (params?: any) =>
  api.sendPost(AUTH_API + endpoints.SMART_LOGOUT, {}, { headers: { 'X-App-Id': X_APP_ID }, params: params });

export const refreshtokenService = (params?: any) =>
  api.sendPost(AUTH_API + endpoints.REFRESHTOKEN, params);

export const signUpService = (params?: any) =>
  api.sendPost(AUTH_API + endpoints.SIGN_UP, params, { headers: { 'X-App-Id': X_APP_ID } });

export const forgetPassword = (params: { email: string; resetPasswordUrl: string }) =>
  api.sendPost(AUTH_API + endpoints.FORGET_PASSWORD, params, { headers: { 'X-App-Id': X_APP_ID } });

export const resetPassword = (params: any) =>
  api.sendPost(AUTH_API + endpoints.RESET_PASSWORD, params);

export const checkResetToken = (params: any) =>
  api.sendPost(AUTH_API + endpoints.CHECK_RESET_TOKEN, params);

export const changePassword = (params: any) =>
  api.sendPost(AUTH_API + endpoints.CHANGE_PASSWORD, params);
