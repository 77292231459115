import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
  width?: string;
}

export default function RemudaLogo({ disabledLink = false, width = '100px' }: Props) {

  const logo = (
    <Box>
      <img width={width} alt={'logo'} src="/logo/remuda-logo.png" />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
