import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import {
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
} from '@mui/material';
// components
import MenuPopover from 'components/MenuPopover';
import { IconButtonAnimate } from 'components/animate';
// hooks
import useAuth from 'hooks/useAuth';
import useIsMountedRef from 'hooks/useIsMountedRef';
// routes
import { PATH_PAGE } from 'routes/paths';
import { useGetUserByName } from 'hooks-query/useUser';
import createAvatar from 'utils/createAvatar';
import { useQueryClient } from 'react-query';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Profile',
    linkTo: PATH_PAGE.profile,
  },
  // {
  //   label: 'Settings',
  //   linkTo: PATH_PAGE.home,
  // },
  // {
  //   label: 'Help',
  //   linkTo: PATH_PAGE.home,
  // },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();

  const { logout } = useAuth();

  const isMountedRef = useIsMountedRef();
  const queryClient = useQueryClient();

  const [open, setOpen] = useState<HTMLElement | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const {
    data: myProfile,
  } = useGetUserByName(localStorage.getItem('username'));


  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleOpenDialog = () => {
    handleClose();
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const displayName = `${myProfile?.firstName?.split(' ')[0]} ${myProfile?.lastName?.split(' ')[0]}`;
  const handleLogout = async () => {
    try {
      queryClient.removeQueries();
      await logout();
      navigate(PATH_PAGE.login, { replace: true });

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      navigate(PATH_PAGE.login, { replace: true });
    }
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar
          alt="Avatar"
        >
          {(displayName && createAvatar(displayName).name) || null}
        </Avatar>


      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
            >
              <Typography variant="button">{option.label}</Typography>
            </MenuItem>
          ))}
        </Stack>

        <MenuItem sx={{ m: 1 }} onClick={handleOpenDialog}>
          <Typography variant="button">Logout </Typography>
        </MenuItem>
      </MenuPopover>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          Log out of System
        </DialogTitle>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleLogout}>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
