import jwtDecode from 'jwt-decode';
import { createContext, ReactNode, useEffect, useReducer } from 'react';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '_types/auth';
// utils
import { handleRefreshToken, isValidToken, setSession } from 'utils/jwt';
// services
import * as services from 'services/auth';
// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
  ForgetPassword = 'FORGETPASSWORD',
  ResetPassword = 'RESETPASSWORD',
  CheckResetToken = 'CHECKRESETTOKEN',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: undefined;
  [Types.ForgetPassword]: undefined;
  [Types.ResetPassword]: undefined;
  [Types.CheckResetToken]: undefined;
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: false,
      };
    case 'FORGETPASSWORD':
      return {
        ...state,
      };
    case 'RESETPASSWORD':
      return {
        ...state,
      };
    case 'CHECKRESETTOKEN':
      return {
        ...state,
      };
    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const { accessToken, refreshToken } = JSON.parse(
          localStorage.getItem(process.env.REACT_APP_LOCAL_TOKEN!) || '{}'
        );

        if (refreshToken && isValidToken(accessToken)) {
          let userData = await handleRefreshToken(refreshToken);
          const { roles } = jwtDecode<{ roles: string[] }>(userData?.token);
          userData = { ...userData, roles };
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user: userData,
            },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    const response = await services.loginService({
      username: email,
      password,
    });
    if (response) {
      let userData = response as AuthUser;
      if (userData) {
        setSession(userData);
        const { roles } = jwtDecode<{ roles: string[] }>(userData?.token);
        userData = { ...userData, roles };
        localStorage.setItem('userData', JSON.stringify(userData));
      }
      dispatch({
        type: Types.Login,
        payload: {
          user: userData,
        },
      });
    }
  };

  const register = async (firstName: string, lastName: string, email: string, password: string) => {
    await services.signUpService({
      firstName: firstName,
      lastName: lastName,
      email: email,
      username: email,
      password,
      termsAndConditionsChecked: true,
    });

    dispatch({
      type: Types.Register,
    });
  };

  const logout = async () => {
    const { accessToken, refreshToken } = JSON.parse(
      localStorage.getItem(process.env.REACT_APP_LOCAL_TOKEN!) || '{}'
    );

    if (refreshToken) {
      const params = { refreshToken };
      await services.smartLogoutService(params);
    }
    else {
      await services.logoutService();
    }

    setSession({});
    dispatch({ type: Types.Logout });
  };

  const forgetPassword = async (email: string) => {
    const params = { email, resetPasswordUrl: `${window.location.origin}/reset-password` };
    await services.forgetPassword(params);
    dispatch({ type: Types.ForgetPassword });
  };

  const resetPassword = async (newPassword: string, resetToken: string) => {
    const params = { newPassword, resetToken };
    await services.resetPassword(params);
    dispatch({ type: Types.ResetPassword });
  };

  const checkResetToken = async (resetToken: string) => {
    const params = { resetToken };
    await services.checkResetToken(params);
    dispatch({ type: Types.CheckResetToken });
  };

  const changePassword = async (currentPassword: string, newPassword: string) => {
    const params = { currentPassword, newPassword };
    await services.changePassword(params);
    dispatch({ type: Types.ResetPassword });
  };


  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        forgetPassword,
        resetPassword,
        checkResetToken,
        changePassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
