// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, Typography } from '@mui/material';

// react
import { useMemo } from 'react';
import { useLocation } from 'react-router';
// hooks
import useOffSetTop from 'hooks/useOffSetTop';
import useResponsive from 'hooks/useResponsive';
// utils
import cssStyles from 'utils/cssStyles';
// config
import { HEADER, NAVBAR } from 'config';
// components
import Logo from 'components/Logo';
import Iconify from 'components/Iconify';
import AccountPopover from './AccountPopover';
import { IconButtonAnimate } from 'components/animate';

// routes
import { PATH_PAGE } from 'routes/paths';

// ----------------------------------------------------------------------

type RootStyleProps = {
  isCollapse: boolean;
  isOffset: boolean;
  verticalLayout: boolean;
};

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})<RootStyleProps>(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  borderBottom: '1px solid #ccc',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('xl')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  onOpenSidebar: VoidFunction;
  isCollapse?: boolean;
  verticalLayout?: boolean;
};

export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}: Props) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'xl');

  const headingPage: string = useMemo(() => {
    if (pathname === PATH_PAGE.users) return 'User List';
    if (pathname === PATH_PAGE.devices) return 'Device List';
    if (pathname === PATH_PAGE.accounts) return 'Account List';
    if (pathname === PATH_PAGE.profile) return 'Your Profile';
    if (pathname === PATH_PAGE.deviceLogs || pathname.includes(PATH_PAGE.deviceLogs)) return 'Device Logs';
    if (pathname === PATH_PAGE.appLogs || pathname.includes(PATH_PAGE.appLogs)) return 'App Logs';

    return 'Overview';
  }, [pathname]);

  return (
    <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
          height: 60,
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}
        <Typography variant="h5" sx={{ color: '#212B36' }}>
          {headingPage}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <AccountPopover />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
