// ----------------------------------------------------------------------

// function path(root: string, sublink: string) {
//   return `${root}${sublink}`;
// }

export const PATH_PAGE = {
  //user
  //oder
  orders: '/orders',
  order: '/order',
  //event
  events: '/events',
  eventCreate: '/event/create',
  //auth
  login: '/login',
  signup: '/signup',
  verify: '/verify',
  resetPassword: '/resetPassword',
  forgotPassword: '/forgotPassword',
  //error
  page403: '/403',
  page404: '/404',
  page500: '/500',
  //main
  accounts: '/accounts',
  devices: '/devices',

  users: '/users',
  deviceLogs: '/device-logs',
  deviceLogByDevice: '/device-logs/:deviceId',

  appLogs: '/app-logs',
  appLogByUser: '/app-logs/:userId',

  profile: '/profile',

};
