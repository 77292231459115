import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
// services
import * as api from 'services/user';
import { GetUsersParamsType } from '_types/user';

const KEY = {
  GET_USER_LIST: ['api', 'user', 'search'],
  GET_USER: 'GET_USER',
};

export const useGetUserList = (params: GetUsersParamsType) =>
  useQuery<any, AxiosError>({
    queryKey: [...KEY.GET_USER_LIST, params],
    queryFn: async () => {
      const response: any = await api.getUserList(params);
      return response;
    },
  });

export const useGetUserByName = (userName: string | null) =>
  useQuery<any, AxiosError>({
    queryKey: KEY.GET_USER,
    queryFn: async () => {
      const response: any = await api.getUserByUserName(userName as string);
      return response.data;
    },
    enabled: !!userName,
  });

export const useUpdateUserProfile = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: api.updateUserProfile,
    onSuccess: (result: any) => {
      queryClient.invalidateQueries({
        queryKey: KEY.GET_USER,
      });
    },
    onError: () => {},
  });
};
export const useCreateUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: api.createUser,
    onSuccess: (result: any) => {
      queryClient.invalidateQueries({
        queryKey: KEY.GET_USER_LIST,
      });
    },
    onError: () => {},
  });
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: api.updateUserProfile,
    onSuccess: (result: any) => {
      queryClient.invalidateQueries({
        queryKey: KEY.GET_USER_LIST,
      });
    },
    onError: () => {},
  });
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: api.deleteUser,
    onSuccess: (result: any) => {
      queryClient.invalidateQueries({
        queryKey: KEY.GET_USER_LIST,
      });
    },
    onError: () => {},
  });
};
