// routes
import { PATH_PAGE } from 'routes/paths';

// ----------------------------------------------------------------------

// const getIcon = (name: string) => (
//   <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
// );

// const ICONS = {
//   artificialIntelligence: getIcon('ic_artificial_intelligence'),
//   action: getIcon('ic_action'),
//   scorecard: getIcon('ic_scorecard'),
//   chat: getIcon('ic_chat'),
//   chatQuestionnaire: getIcon('ic_chat_questionnaire'),
//   settings: getIcon('ic_settings'),
//   account: getIcon('ic_account'),
// };

const navConfig = [
  // MAIN
  // ----------------------------------------------------------------------
  {
    subheader: 'Remuda',
    items: [
      { title: 'Devices', path: PATH_PAGE.devices },
      { title: 'Users', path: PATH_PAGE.users },
      { title: 'Accounts', path: PATH_PAGE.accounts },
    ],
  },
];
const navBottomConfig = [
  // BOTTOM
  // ----------------------------------------------------------------------
  {
    subheader: 'RemudaLogs',
    items: [
      {
        title: 'Logs', path: '',
        children: [
          { title: 'Device Logs', path: '/device-logs' },
          { title: 'App Logs', path: '/app-logs' },
          // { title: 'System Logs', path: '/system-logs' },
        ],
      },

    ],
  },
];
export { navConfig, navBottomConfig };
