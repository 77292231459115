import * as api from 'utils/axios';
import * as endpoints from 'services/user/endpoints';
import { GetUsersParamsType } from '_types/user';

export const getUserList = ({
  searchKey,
  pageIndex,
  pageSize,
  sortBy,
  sortDirection,
}: GetUsersParamsType) =>
  api.sendGet(
    `${endpoints.SEARCH_USER_LIST}?text=${searchKey}&pageIndex=${pageIndex}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDirection.toUpperCase()}`,
    {
      headers: { 'Content-Type': 'application/json', accept: 'application/json' },
      data: {},
    }
  );

export const createUser = (params: any) => api.sendPost(endpoints.CREATE_USER, params);

export const getUserByUserName = (name: string) =>
  api.sendGet(`${endpoints.GET_USER}?username=${name}`, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const updateUserProfile = (params: any) =>
  api.sendPut(`${endpoints.UPDATE_USER_PROFILE}/${params.username}`, params, {
    headers: { 'Content-Type': 'application/json' },
  });

export const deleteUser = (userName: string) =>
  api.sendDelete(`${endpoints.DELETE_USER}/${userName}`, {
    headers: { 'Content-Type': 'application/json' },
    data: {
      userName,
    },
  });
