import { ElementType, lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// hooks
import useAuth from 'hooks/useAuth';
// layouts
import DashboardLayout from 'layouts/dashboard';
import LogoOnlyLayout from 'layouts/LogoOnlyLayout';
// guards
import GuestGuard from 'guards/GuestGuard';
// components
import LoadingScreen from 'components/LoadingScreen';
import AuthGuard from 'guards/AuthGuard';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to="login" replace />,
    },
    {
      path: 'login',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    // {
    //   path: 'sign-up',
    //   element: (
    //     <GuestGuard>
    //       <SignUp />
    //     </GuestGuard>
    //   ),
    // },
    // {
    //   path: '/signup/:key',
    //   element: (
    //     <GuestGuard>
    //       <SignUp />
    //     </GuestGuard>
    //   ),
    // },
    // {
    //   path: '/verify',
    //   element: <VerifyCode />,
    // },
    // {
    //   path: 'forgotpassword',
    //   element: <ForgotPassword />,
    // },
    // {
    //   path: 'resetPassword',
    //   element: <ResetPassword />,
    // },
    {
      path: '*',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'users', element: <Users /> },
        { path: 'devices', element: <Devices /> },
        { path: 'accounts', element: <Accounts /> },
        { path: 'profile', element: <UserProfile /> },
        {
          path: 'device-logs',
          children: [
            { element: <DeviceLogs />, index: true },
            { path: ':deviceId', element: <DeviceLogs /> },
          ]
        },
        {
          path: 'app-logs',
          children: [
            { element: <AppLogs />, index: true },
            { path: ':userId', element: <AppLogs /> },
          ]
        },

      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    // { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

//ERROR
const Page500 = Loadable(lazy(() => import('pages/Page500')));
const Page403 = Loadable(lazy(() => import('pages/Page403')));
const Page404 = Loadable(lazy(() => import('pages/Page404')));
// AUTHENTICATION
const Login = Loadable(lazy(() => import('pages/Login')));
const SignUp = Loadable(lazy(() => import('pages/SignUp')));
const VerifyCode = Loadable(lazy(() => import('pages/VerifyCode')));
const ForgotPassword = Loadable(lazy(() => import('pages/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('pages/ResetPassword')));
// MAIN
const Accounts = Loadable(lazy(() => import('pages/AccountList')));
const Users = Loadable(lazy(() => import('pages/UserList')));
const Devices = Loadable(lazy(() => import('pages/DeviceList')));


// User
const UserProfile = Loadable(lazy(() => import('pages/user/UserProfile')));

//Logs
const DeviceLogs = Loadable(lazy(() => import('pages/DeviceLogs')));
const AppLogs = Loadable(lazy(() => import('pages/AppLogs')));

